<template>
  <div style="background:#FFFFFF">
    <div class="header-nav">
      <span class="hn-one" @click="returnFor">订单数据</span>
      <a-icon class="hn-two" type="right" />
      <span v-if="id">{{id.fullName}}</span>
    </div>
    <div class="container">
      <div class="header"><span class="line"></span> {{id.fullName}}</div>
      <div class="nav">
      </div>
      <div class="search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="24">
            <a-col :lg="6" :md="8" :sm="12">
              <a-form-item label="支付方式" :labelCol="{lg: {span: 6}, sm: {span: 7}}" :wrapperCol="{lg: {span: 18}, sm: {span: 17} }">
                <a-select style="width: 200px" placeholder="请选择支付方式" v-model="queryParam.payType">
                  <a-select-option value="20">支付宝</a-select-option>
                  <a-select-option value="30">微信</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :lg="6" :md="8" :sm="12">
              <span class="table-page-search-submitButtons">
                <a-button type="primary" @click="handleToSearchEnterprise">查询</a-button>
                <a-button style="margin-left: 8px" icon="reload" @click="resetSearchEnterprise">重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <div>
        <s-table
          style="border-top:1px solid #F0F0F0;margin-top:16px"
          ref="table"
          :columns="raskColumns"
          :data="raskData"
          :rowKey="(record) => record.id"
        >
          <span slot="action" slot-scope="text, record">
            <a @click="freeze(record)" v-if="record.status==='100201'">冻结员工</a>
            <a @click="refreeze(record)" v-else>解冻员工</a>
            <a-divider type="vertical" />
            <a @click="reset(record)">重置密码</a>
          </span>
        </s-table>
      </div>
      <!-- 添加 -->
      <a-modal
        title="添加员工账户"
        :visible="visibleAdd"
        :confirm-loading="confirmLoadingAdd"
        @ok="handleOkAdd"
        @cancel="handleCancelAdd"
      >
        <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }" @submit="handleOkAdd">
          <a-form-item label="邮箱">
            <a-input
              placeholder="请填写邮箱"
              v-decorator="['email', { rules: [{ required: true, message: '请填写邮箱!' }] }]"
            />
          </a-form-item>
          <a-form-item label="姓名">
            <a-input
              placeholder="请填写姓名"
              v-decorator="['userName', { rules: [{ required: true, message: '请填写姓名!' }] }]"
            />
          </a-form-item>
          <a-form-item label="权限类型">
            <a-select
              v-decorator="[
                'roleType',
                { rules: [{ required: true, message: '请选择!' }] },
              ]"
              placeholder="请选择权限类型"
              @change="handleSelectChange"
            >
              <a-select-option value="102011">
                普通用户
              </a-select-option>
              <a-select-option value="102001">
                管理员
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
      </a-modal>
    </div>
  </div>
</template>
<script>
import STable from '@/components/table/';
import {
  getUserInfoList,
  addEnterpriseAccount,
  resetUserPwd,
  freezeEnterpriseAccount,
  unLock,
  getOrderDetail
} from '@/api/api';
export default {
  components: {
    STable
  },
  data () {
    return {
      raskColumns: [
        {
          title: '支付人昵称',
          dataIndex: 'nickName',
          align: 'center'
        },
        {
          title: '支付金额',
          dataIndex: 'payAmount',
          align: 'center'
        },
        {
          title: '支付时间',
          dataIndex: 'payTime',
          align: 'center'
        },
        {
          title: '支付方式',
          dataIndex: 'payType',
          customRender: text => text==='20' ? '微信' : '支付宝',
          align: 'center'
        },
      ],
      visibleAdd: false,
      confirmLoadingAdd:false,
      formLayout: 'horizontal',
      form: this.$form.createForm(this, { name: 'coordinated' }),
      queryParam: {
        enterpriseName: '',
        fullName: '',
        payType: undefined,
      },
      id: ''
    }
  },
  created () {
    this.id = JSON.parse(window.localStorage.getItem('orderInfo'))
  },
  methods: {
    // 返回
    returnFor () {
      window.localStorage.setItem('submenu','polyPay')
      window.localStorage.removeItem('orderInfo')
      this.$router.push('/polyPay')
    },
    // 获取列表
    raskData (parameter) {
      return getOrderDetail(Object.assign(parameter,{orderId:this.id.id},this.queryParam)).then(res => {
        return res.result
      })
    },
    // 查询    
    handleToSearchEnterprise() {
      this.$refs.table.refresh(true)
    },
    // 重置
    resetSearchEnterprise() {
      this.queryParam = {
        enterpriseName: '',
        fullName: '',
        payType: undefined,
      };
      this.handleToSearchEnterprise();
    },
    // 添加
    handleOkAdd (e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.confirmLoadingAdd = true
          addEnterpriseAccount(values).then(res=> {
            this.confirmLoadingAdd = false
            if (res.success) {
              this.$message.success(res.message)
              this.$refs.raskTable.refresh(true)
              this.handleCancelAdd()
            } else {
              this.$message.error(res.message)
            }
          })
        }
      });
    },
    handleCancelAdd () {
      this.form.resetFields()
      this.visibleAdd = false
    },
    // 冻结
    freeze (record) {
      let that = this
      this.$confirm({
        title: '确定冻结' + record.userName + '吗?',
        onOk() {
          return new Promise((resolve, reject) => {
            freezeEnterpriseAccount({id:record.id}).then(res => {
            if (res.success) {
              that.$message.success(res.message)
              that.$refs.raskTable.refresh(true)
              resolve()
            } else {
              that.$message.error(res.message)
              resolve()
            }
          })
          }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {},
      });
    },
    // 解冻
    refreeze (record) {
      let that = this
      this.$confirm({
        title: '确定解冻' + record.userName + '吗?',
        onOk() {
          return new Promise((resolve, reject) => {
            unLock({id:record.id}).then(res => {
              if (res.success) {
                that.$message.success(res.message)
                that.$refs.raskTable.refresh(true)
                resolve()
              } else {
                that.$message.error(res.message)
                resolve()
              }
            })
          }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {},
      });
    },
    // 重置密码
    reset (record) {
      let that = this
      this.$confirm({
        title: '确定重置' + record.userName + '的密码?',
        onOk() {
          return new Promise((resolve, reject) => {
            resetUserPwd({id:record.id}).then(res => {
              if (res.success) {
                that.$message.success(res.message)
                that.$refs.raskTable.refresh(true)
                resolve()
              } else {
                that.$message.error(res.message)
                resolve()
              }
            })
          }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {},
      });
    },
  }
}
</script>
<style scoped lang="less">
.container{
  background: #FFFFFF;
  border-radius: 4px;
  height: 935px;
  padding: 40px;
}
.header-nav{
  display: flex;
  align-items: center;
  color: #333333;
  font-size: 14px;
  padding: 17px 0 17px 34px;
  border-bottom: 1px solid #DEDEDE;
  .hn-one{
    color: #000000;
    cursor: pointer;
  }
  .hn-two{
    margin: 0 15px;
    color: #DEDEDE;
    font-size: 18px;
  }
}
.header{
  .line{
    width: 2px;
    height: 18px;
    background: #347CF5;
    margin-right: 9px;
  }
  color: #333333;
  font-size: 18px;
  display: flex;
  align-items: center;
}
.nav{
  margin: 10px 0 0 0;
  .common{
    color: #999999;
    margin: 0;
  }
}
.opt{
  margin: 20px 0 30px 0;
}
.search-wrapper{
  margin: 30px 0;
}
</style>